.container-grid {
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: minmax(50px, auto);
    grid-auto-flow: dense;
    row-gap: 10px;
    column-gap: 10px;
    position: relative;
    z-index: 3;
    padding: 0 100px;

    &.sm {
      grid-auto-rows: minmax(245px, auto);
      row-gap: 10px;
      column-gap: 10px;
    }

    .item {
      @for $i from 1 through 12 {
        &.column-#{$i} {
          grid-column-end: span $i;
        }
      }

      @for $i from 1 through 4 {
        &.row-#{$i} {
          grid-row-end: span $i;
        }
      }
    }
  }
}
