@import 'colorVariables';

/* Font Weight*/
$weights: 300, 400, 500, 600, 700;
@each $weight in $weights {
  .w-#{$weight} {
    font-weight: #{$weight};
  }
}

/* Text Align */
$aligns: left, center, right, justify;
@each $align in $aligns {
  .text-#{$align} {
    text-align: #{$align};
  }
}

/* Margin Padding */
.my-1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.my-2 {
  margin-top: 20px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mt-3 {
  margin-top: 10px;
  @media (min-width: 768px) {
    margin-top: 20px;
  }
}

.mt-5 {
  margin-top: 15px;
  @media (min-width: 768px) {
    margin-top: 50px;
  }
}

.ml-1 {
  margin-left: 20px;
}

.mt-10 {
  margin-top: 25px;
  @media (min-width: 768px) {
    margin-top: 100px;
  }
}

.pl-1 {
  padding-left: 50px;
}

.px-5 {
  padding-right: 15px;
  padding-left: 15px;
  @media (min-width: 768px) {
    padding-right: 50px;
    padding-left: 50px;
  }
}

.py-3 {
  padding-left: 32px;
  padding-right: 32px;
  @media (min-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
}

/* Animation */
@keyframes myMove {
  0% {
    left: 0;
  }
  25% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}

@keyframes myMoves {
  0% {
    left: 0;
  }
  25% {
    left: -100vw;
  }
  100% {
    left: 0;
  }
}

/* Button */
.btn-black {
  background: $black;
  color: $white;
}
.btn-green {
  background: $primaryGreen;
  color: $white;

  &:hover {
    background-color: lighten($primaryGreen, 5);
    transition: 0.3s all ease-in-out;
    color: $white;
  }
}

.btn-shadow {
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.25);
}

.btn-lg {
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-top: 7px;
}
.btn-sm {
  border-radius: 5px;
  padding: 0.3rem 0.7rem;
  font-size: 0.725rem;
  text-align: center;
  margin: 10px 0;

  i {
    margin: 0 5px;
  }
}

.btn-primary {
  background-color: #2b364c;
  color: white;
}

.btn-secondary {
  background-color: #607ff2;
  color: white;
}

.accordion {
  display: flex;
  min-height: 44px;
  min-width: 44px;
  align-items: center;
}

.btn-accordion {
  font-size: 1rem;
  padding: 0.25rem 1rem;
  margin-right: 10px;

  &.active {
    background-color: $primaryGreen;
    color: white;
  }

  &:hover {
    background-color: $primaryGreen;
    color: white;
  }
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.text-white {
  color: $white;
}

.title {
  color: $black;
  font-size: 1.5rem;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
}

.subtitle {
  font-size: 0.925rem;
  margin: 5px 0;
  color: $black;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
}

.description {
  color: $black;
  font-size: 0.825rem;
  margin: 10px 0;
}
.description-2 {
  color: $black;
  font-size: 0.975rem;
  margin: 10px 0;
  line-height: 1.75;
  max-width: 520px;
}

.text-primary {
  color: $primaryGreen;
}

.d-block {
  display: block;
  width: 100%;
}

.d-inline {
  display: inline;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}
.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.border-bottom {
  border-bottom: 1px solid rgba($primaryGreen, 0.25);
}

.bread-crumb {
  font-size: 1.25rem;
  font-weight: 400;
}

.sm-center {
  text-align: center;
  justify-content: center;
  @media (min-width: 768px) {
    text-align: left;
    justify-content: flex-start;
  }
}

.gap {
  gap: 20px;
}
