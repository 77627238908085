/* Basic style */
.skeleton {
  background-color: #ddd;
  margin: 10px 0;
  border-radius: 4px;

  &.text {
    width: 100%;
    height: 12px;
  }

  &.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
  }

  &.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }

  &.thumbnail {
    width: 100px;
    height: 100px;
  }

  &.image {
    width: 100%;
    height: 150px;
  }
}

.skeleton-wrapper {
  position: relative;
  margin: 10px 5px;
  padding: 10px;
  background-color: #eeeeee;
  border-radius: 6px;
  overflow: hidden;
}

/* animation effect */
.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: loading 1.5s infinite;

  .shimmer {
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05);
  }
}

@keyframes loading {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}
