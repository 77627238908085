@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@import 'reset';
@import 'colorVariables';
@import 'utils';
@import 'grid';
@import 'productBar';

body {
  font-family: 'Poppins', sans-serif;

  &.hidden {
    overflow: hidden;
  }
}

.navbar {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  @media (min-width: 810px) {
    padding: 10px 100px;
  }

  .navbar_expand {
    position: relative;
    z-index: 100;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar_toggler {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      min-width: 44px;
      min-height: 44px;
      transform: translateY(-5px);
      .bar {
        position: relative;
        width: 25px;
        height: 3px;
        background-color: $primaryGreen;
        transition: 0.5s all ease-in-out;
        border-radius: 51px;

        &::before {
          position: absolute;
          content: '';
          top: 10px;
          width: inherit;
          height: inherit;
          border-radius: inherit;
          background-color: $primaryGreen;
          transition: 0.5s all ease-in-out;
        }
        &.close {
          background-color: $white;
          transform: rotateZ(45deg);
        }

        &.close::before {
          background-color: $white;
          top: 0;
          transform: rotateZ(-90deg);
        }
      }

      @media (min-width: 768px) {
        display: none;
      }
    }
  }

  .navbar_nav {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 100vh;
    width: 100%;
    text-align: center;
    place-content: center;
    display: grid;
    gap: 10px;
    background-color: $lightGreen;
    transform: translateX(-100vw);
    transition: 0.3s all ease-in-out;
    z-index: 99;
    .nav_item {
      margin: 0 12px;
    }

    .nav_link {
      font-size: 2rem;
      font-weight: 600;
      color: $white;

      &:hover {
        color: $primaryGreen;
        transition: 0.3s all ease-in-out;
      }
    }

    &.collapse {
      transform: translateX(0);
      transition: 0.5s all ease-in-out;
    }

    @media (min-width: 768px) {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      background-color: transparent;
      transform: translateX(0);
      height: fit-content;
      width: 100%;

      .nav_link {
        font-size: 1rem;
        font-weight: 400;
        color: $black;
      }
    }
  }
}

.brand_logo {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  .image_logo {
    width: 36px;
    height: 50px;
    display: none;

    @media (min-width: 768px) {
      display: inline-block;
    }
  }

  .brand_name {
    display: inline-block;
    color: $primaryGreen;
    font-size: 18px;
    font-weight: bold;
    border-bottom: 1px solid $white;

    span {
      font-weight: 300;
    }

    @media (min-width: 768px) {
      color: $primaryGreen;
      border-bottom: none;
      span {
        color: $secondaryGreen;
      }
    }
  }

  &.open {
    animation: myMove 1s ease-in-out;

    .brand_name {
      color: $white;
      transition: 1s color ease-in-out;
      transition-delay: 1s;
    }
  }

  &.close {
    animation: myMoves 1s ease-in-out;
  }
}

.hero {
  display: flex;
  flex-direction: column-reverse;

  .hero_left_content {
    position: relative;
    padding: 0 32px;
    margin: 10px 0;
    text-align: center;
  }
  .hero_right_content {
    position: relative;
    width: 100%;
    min-height: 200px;
    height: 100%;

    .hero_image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 30px;
    min-height: calc(100vh - 70px);

    .hero_left_content {
      grid-column-end: span 3;
      text-align: left;
      padding: 0 0 0 100px;
      top: 25%;
    }

    .hero_right_content {
      grid-column-end: span 2;

      .hero_image {
        height: calc(100vh - 70px);
      }
    }
  }
}

.tagline {
  font-size: 1.25rem;
  color: $black;

  line-height: 1.5;
  margin: 1rem 0;

  @media (min-width: 768px) {
    font-size: 1.75rem;
    max-width: 500px;
  }
}

.slug {
  font-size: 0.825rem;
  color: $black;

  font-weight: 500;
  margin: 1rem 0;

  @media (min-width: 768px) {
    max-width: 500px;
  }
}

.image-artistic {
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  height: 400px;
  z-index: -1;
  object-fit: contain;
}
.image-artistic2 {
  position: absolute;
  bottom: -150px;
  width: 30%;
  left: 0;
  z-index: -1;

  @media (min-width: 768px) {
    bottom: 0;
  }
}

@media (min-width: 768px) {
  .rectangle-border {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 25px;
    left: -25px;
    border: 1px solid rgba($secondaryGreen, 0.5);
    border-radius: 10px;
    z-index: -1;
  }
}

.icon_name {
  display: inline-block;
  color: $primaryGreen;

  span {
    color: $secondaryGreen;
  }
}

/* Our Timber */
.our_timber {
  min-height: 250px;
  padding: 0 32px;
  margin-top: 100px;

  h1 {
    font-size: 1.75rem;
    margin: 10px;
    width: max-content;
    margin: 0 auto;
    border-bottom: 1px solid rgba($primaryGreen, 0.5);
  }
  .timber_container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .timber {
      text-align: center;
      margin: 10px 0;
    }

    .timber_desc {
      h3 {
        font-size: 1.25rem;
      }
      p {
        font-size: 0.75rem;
      }
    }

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 0 100px;

      .timber {
        text-align: left;
        display: flex;
        gap: 10px;
      }
    }
  }

  .image-timber {
    width: 108px;
    height: 108px;
  }
}

.most_products {
  margin-top: 100px;
  min-height: 400px;

  .most-container {
    display: none;

    @media (min-width: 768px) {
      display: grid;
    }
  }

  h1 {
    color: $black;
    font-size: 1.75rem;
    padding: 0 32px;
    margin: 10px 0;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
      padding: 0 100px;
    }
  }

  .card_product {
    overflow: hidden;
    border-radius: 5px;
  }

  .img-wrapper {
    width: 100%;
    height: 100%;
  }

  .slider {
    display: contents;
    @media (min-width: 768px) {
      display: none;
    }

    .swiper-wrapper {
      height: 300px !important;
    }
  }

  .item {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: 0.5s all ease-in-out;
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
      z-index: 1;
    }

    &:hover .meta-wrapper {
      transform: translateY(-10px);

      transition: 0.3s all ease-in-out;
    }

    &:hover::after {
      background: linear-gradient(to top, rgb(0, 0, 0), transparent);
      transition: 0.5s all ease-in-out;
    }
  }
  .meta-wrapper {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    color: $white;
    padding: 30px 20px;
    z-index: 2;
    font-size: 1.25rem;
  }
}

.footer {
  display: flex;
  flex-direction: column;

  background-color: $black;
  color: $white;
  font-weight: 300;
  padding: 20px 32px;

  .footer_company {
    width: 315px;
    font-size: 0.75rem;
    margin-top: 20px;

    P {
      margin-top: 20px;
    }

    .barqode {
      width: 125px;
    }
  }

  .footer_product {
    margin-top: 20px;
    .item_product {
      font-size: 0.825rem;
    }
  }

  .footer_contact {
    width: 315px;
    font-size: 0.825rem;
    margin-top: 20px;

    .map,
    .telp {
      display: flex;
      align-items: flex-start;
    }
  }

  i {
    font-size: 1rem;
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    justify-content: space-between;
    flex-direction: row;
    padding: 20px 100px;
    margin-top: 100px;
    .footer_company {
      font-size: 0.825rem;
    }

    i {
      font-size: 1.25rem;
    }
  }
}

/* About */
.about {
  display: grid;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  margin: 30px 0;

  .item-2 {
    max-width: 500px;
    transform: translateY(-30px);
  }
  .img-cover {
    width: 50%;
    margin: 0 auto;
  }
}

.mission {
  display: grid;
  padding: 0 32px;
  position: relative;
  gap: 30px;
  margin-bottom: 50px;
  margin-top: 100px;

  .card_mission {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: darken($white, 2);
    border-radius: 10px;
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.15);
  }

  .item {
    margin: 10px 0;
  }
  .img-cover {
    width: 100px;
    height: 100px;
    margin: 0 auto;
  }

  .image-artistic {
    transform: rotate(180deg);
  }

  @media (min-width: 768px) {
    padding: 0 100px;
    .item {
      width: 500px;
      margin: 0 auto;
    }
  }
}

.contact {
  padding: 0 32px;

  i {
    color: $primaryGreen;
    font-size: 1.75rem;
  }

  @media (min-width: 768px) {
    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      background-image: url('/asset/images/street-map.jpg');
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: -1;
    }
  }
}

.card_contact {
  width: 100%;
  height: 100%;
  padding: 10px 20px 0 20px;
  background-color: darken($white, 2);
  border-radius: 10px;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.15);
  margin: 10px auto;
  text-align: center;

  &:hover {
    background-color: $primaryGreen;
    transition: 0.3s all ease-in-out;

    i {
      color: $white;
    }

    .subtitle {
      color: $white;
    }
  }
}

.form {
  justify-self: right;
}

.form_container {
  max-width: 650px;

  padding: 20px;
  background-color: darken($white, 2);
  border-radius: 10px;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.15);

  @media (min-width: 768px) {
    width: 500px;
  }
}

.form-input {
  & > * {
    display: block;
    margin-bottom: 8px;
  }

  label {
    font-weight: 300;
    font-size: 0.925rem;
  }

  .input {
    width: 100%;
    min-height: 28px;
    background-color: rgba(#dddddd, 0.7);
    color: $black;
    border: none;
    padding: 3px 5px;

    border-radius: 4px;

    &:focus {
      outline: none;
      border: 1px solid $secondaryGreen;
    }
  }

  textarea.input {
    height: 5rem;
    resize: none;
    width: 100%;
  }
}

.barqode {
  width: 50%;
}

.card {
  background-color: darken($white, 2);
  border-radius: 10px;
  box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.product_list {
  padding: 0 32px;
  gap: 30px;

  @media (min-width: 768px) {
    padding: 0 32px 0 0;
  }

  .container-product {
    padding: 0;
    gap: 30px;
  }

  .card_product {
    position: relative;
    min-height: 400px;
  }

  .meta-wrapper {
    position: absolute;
    bottom: 0;
    padding: 10px 15px;

    .description {
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;
      max-height: 3em;
      line-height: 1.5em;
      font-size: 0.8rem;
    }
    .container-grid {
      padding: 0;
      margin: 0;
    }
  }
}

.product_detail {
  .big-image {
    margin: 10px 0;
    padding: 0;
  }

  .small-image {
    &:hover {
      cursor: pointer;
    }
  }
}

.table-specification {
  tr > td {
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    @media (min-width: 768px) {
      padding: 10px 0;
    }
  }
  tr > td:first-child {
    display: flex;
    padding-right: 15px;
    min-width: 100px;
  }
}

.dimension {
  display: grid;
  max-width: 600px;
  grid-template-columns: repeat(4, 1fr);
}

// LOGIN
.login {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 3px 13px rgba(0, 0, 0, 0.2);
  width: 400px;
}

.login__title {
  font-weight: 300;
  font-size: 1.5rem;
  margin: 10px 0;
  font-weight: 500;
}

.login__text {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: none;
}

.login__text:focus {
  outline: none;
}

.dashboard {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.success {
  color: #2b364c;
}

.text-lg {
  display: inline;
  font-size: 2rem;
}
