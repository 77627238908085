* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;

  &:hover {
    color: #37c96f;
  }
}

button {
  cursor: pointer;
  border: none;
  background: none;
  font-family: 'Poppins', sans-serif;
}

ul,
ol,
li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
