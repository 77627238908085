@import 'colorVariables';

.product_bar {
  position: relative;
  width: 100%;
  height: 100%;
}

.product_dropdown {
  font-size: 1rem;
  // margin: 5px 0;
  font-weight: 500;

  @media (min-width: 768px) {
    text-align: left;
    font-size: 1rem;
    font-weight: 300;

    i {
      display: none;
    }
  }
}
.product_items {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 200px;
  padding: inherit;
  width: 100%;
  padding: 0 32px;
  margin: 0 auto;
  background-color: $primaryGreen;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transform: scaleY(0);
  transition: all 0.35s;
  z-index: 99;

  &.open {
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
  }

  @media (min-width: 768px) {
    margin-top: 10px;
    visibility: visible;
    opacity: 1;
    transform: scaleY(1);
    position: relative;
    top: 0;
    left: 0;
    right: initial;
    width: 100%;
    height: 400px;
    padding: 20px;
    background-color: $primaryGreen;
    border-radius: 10px;
    box-shadow: 3px 4px 15px rgba(0, 0, 0, 0.15);

    ul {
      position: fixed;
      top: 0;
    }
  }

  // @media (min-width: 768px) {
  //   width: 300px;
  //   padding: 0 10px;
  //   top: 0;
  //   right: 100px;
  //   left: unset;
  //   margin-top: 50px;
  // }
}

.product_item {
  margin: 8px 0;
  border-bottom: 1px solid rgba($white, 0.5);
  font-size: 0.85rem;

  &.active {
    button {
      color: rgb(255, 208, 0);
    }
  }

  button {
    color: $white;

    &:hover {
      color: rgb(255, 208, 0);
      transition: 0.3s color ease-in-out;
    }
  }

  i {
    margin-right: 5px;
  }

  @media (min-width: 768px) {
    margin: 20px 0;

    border-bottom: none;
  }
}
